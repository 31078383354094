import { template as template_567e43ad7acd4556b3ce88a8417bfc1f } from "@ember/template-compiler";
const FKText = template_567e43ad7acd4556b3ce88a8417bfc1f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
