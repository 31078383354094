import { template as template_531c84648e7f46508f838eb554bc70e6 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_531c84648e7f46508f838eb554bc70e6(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
