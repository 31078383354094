import { template as template_ceabdc56409a475b84f1080916009a18 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ceabdc56409a475b84f1080916009a18(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
