import { template as template_990364bda27f432f8bcfde83c2b6d0fa } from "@ember/template-compiler";
const FKLabel = template_990364bda27f432f8bcfde83c2b6d0fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
